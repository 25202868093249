export const AuthOutEndpoints = {
  GESTIONE_MODULO: 'modules',
  GESTIONE_PERMISSION: 'permissions',
  GESTIONE_APPLICAZIONE: 'applications',
  GESTIONE_RUOLO: 'roles',
  GESTIONE_UNITA_ORGANIZZATIVA: 'ou',
  GESTIONE_UTENTE: 'users',
  GESTIONE_USER_INFO: 'userinfo',
  GESTIONE_PROFILO: 'profile',
  GESTIONE_TENANT: 'tenants'
};
