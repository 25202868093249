import {Injectable, inject} from '@angular/core';
import { DataStorageEnum } from '../models/data-storage-enum.model';
import { DataStorage } from './storage.service';
import { MenuItem } from 'primeng/api';
import { PermissionService } from '@pandora/flowers';
import { PermissionEnum } from '../../shared/constans/permissions.enum';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from './navigate.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UiElement {

  permissionService: PermissionService;

  constructor(private readonly dataStorage: DataStorage, private readonly navigateService: NavigateService, private readonly translateService: TranslateService) {
    this.permissionService = inject(PermissionService);
  }

  checkLanguageAvailable(): boolean {
    return this.translateService.getLangs().includes(environment.defaultLang);
  }

  initMenuItems(NO_AUTHORIZATION = false) {
    let menuItems: MenuItem[] = [];

    if (NO_AUTHORIZATION) {
      menuItems = [
        {
          label: this.translateService.instant('MENU.NO_AUTHORIZATION'),
          disabled: true,
          id: 'no-items',
        },
      ];
    } else {
      menuItems = [
        {
          label: 'Mappa Applicativa',
          items: [
            {
              label: this.translateService.instant('GESTIONE_MODULO.SECTION_TITLE'),
              queryParams: { permissions: [PermissionEnum.MENU_MODULE, PermissionEnum.SRCH_MOD] },
              command: () => this.navigateService.goTo('gestione-modulo'),
            },
            {
              label: this.translateService.instant('GESTIONE_APPLICAZIONE.SECTION_TITLE'),
              queryParams: { permissions: [PermissionEnum.MENU_APPLICATION, PermissionEnum.SRCH_APP] },
              command: () => this.navigateService.goTo('gestione-applicazione'),
            }
          ],
        },
        {
          label: this.translateService.instant('GESTIONE_UNITA_ORGANIZZATIVA.SECTION_TITLE'),
          queryParams: { permissions: [PermissionEnum.MENU_UO, PermissionEnum.SRCH_UO] },
          command: () => this.navigateService.goTo('unita-organizzativa'),
        },
        {
          label: this.translateService.instant('GESTIONE_UTENTE.SECTION_TITLE'),
          queryParams: { permissions: [PermissionEnum.MENU_USER, PermissionEnum.SRCH_USER] },
          command: () => this.navigateService.goTo('utente'),
        },
        {
          label: this.translateService.instant('TENANT.SECTION_TITLE'),
          queryParams: { permissions: [PermissionEnum.MENU_TENANT, PermissionEnum.SRCH_TENANT] },
          command: () => this.navigateService.goTo('tenant'),
        }
      ];

      menuItems = this.permissionService.checkMenuPermissions(menuItems);
    }

    this.dataStorage.updateData(DataStorageEnum.MENU_ITEMS, menuItems);
  }

  /* initMenuItems(user: User) {
    const menuItems: MenuItem[] = [];

    if (user && 'profileSelected' in user && user.profileSelected) {
      const permissions: PermissionEnum[] = user.profileSelected.permission;

      const MENU_MODULE = permissions.filter((p) => p === PermissionEnum.MENU_MODULE);
      const MENU_PERMISSION = permissions.filter((p) => p === PermissionEnum.MENU_PERMISSION);
      const MENU_APPLICATION = permissions.filter((p) => p === PermissionEnum.MENU_APPLICATION);
      const MENU_ROLE = permissions.filter((p) => p === PermissionEnum.MENU_ROLE);
      const MENU_UO = permissions.filter((p) => p === PermissionEnum.MENU_UO);
      const MENU_USER = permissions.filter((p) => p === PermissionEnum.MENU_USER);

      if (MENU_MODULE || MENU_PERMISSION || MENU_APPLICATION || MENU_ROLE) {
          const items: MenuItem[] = [];

          if (MENU_MODULE) {
            items.push({
              label: 'Modulo',
              id: 'gestione-modulo',
              command: () => this.router.navigate(['gestione-modulo']),
            });
          }

          if (MENU_PERMISSION) {
            items.push({
              label: 'Permission',
              id: 'gestione-permission',
            });
          }

          if (MENU_APPLICATION) {
            items.push({
              label: 'Applicazione',
              id: 'gestione-applicazione',
            });
          }

          if (MENU_ROLE) {
            items.push({
              label: 'Ruolo',
              id: 'gestione-ruolo',
            });
          }

          menuItems.push({
            label: 'Mappa Applicativa',
            items,
          });
      }

      if (MENU_UO) {
        menuItems.push({
          label: 'Unità Organizzativa',
          id: 'unita-organizzativa',
        });
      }

      if (MENU_USER) {
        menuItems.push({
          label: 'Utente',
          id: 'utente',
        });
      }
    }

    this.dataStorage.updateData(DataStorageEnum.MENU_ITEMS, menuItems);
  } */

  getMenuItems() {
    return this.dataStorage.getData(DataStorageEnum.MENU_ITEMS);
  }

  clearMenuItems() {
    const menuItems: MenuItem[] = [
      {
        label: this.translateService.instant('MENU.TO_LOGIN'),
        disabled: true,
        id: 'no-items',
      },
    ];

    this.dataStorage.updateData(DataStorageEnum.MENU_ITEMS, menuItems);
  }

  clearMenuItemsNoProfileSelected() {
    const menuItems: MenuItem[] = [
      {
        label: this.translateService.instant('MENU.TO_PROFILE'),
        disabled: true,
        id: 'no-items',
      },
    ];

    this.dataStorage.updateData(DataStorageEnum.MENU_ITEMS, menuItems);
  }

  /* clearMenuItems() {
    let subscript = new Subscription();

    const sub = this.translateService.get('MENU.TO_LOGIN').subscribe((translated: string) => {
      console.log('translated', translated);
      const menuItems: MenuItem[] = [
        {
          label: translated,
          disabled: true,
          id: 'no-items',
        },
      ];
  
      this.dataStorage.updateData(DataStorageEnum.MENU_ITEMS, menuItems);
      subscript.unsubscribe();
    });

    subscript.add(sub);
  } */
}
