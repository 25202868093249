import { Component, Injector } from '@angular/core';
import { AuthService, FlowersBasePageComponent } from '@pandora/flowers';
import { UiElement } from './core/services/ui-element.service';
import { UserService } from './core/services/user.service';
import { GestioneUserInfoService } from './shared/services/gestione-user-info.service';
import { map, takeUntil } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'flowers-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends FlowersBasePageComponent {

  hideLayout: boolean = false;

  constructor(
    injector: Injector,
    public uiElement: UiElement,
    public userService: UserService,
    public gestioneUserInfoService: GestioneUserInfoService,
    private authService: AuthService,
    private router: Router,
    private location: Location,
  ) {
    super(injector);
  }

  pageName(): string {
    return 'AppComponent';
  }

  ngOnInitForChildren(): void {
    this.checkRouteChange();
  }

  checkRouteChange(): void {
    /* Sia gli eventi di Router tipo NavigationEnd che location.onUrlChange 
    ** non partono in caso di redirect alla route di default;
    ** activatedRoute è l'unico ad attivarsi sempre.
    ** per via della posizione di appComponent, l'url ottenuto dal subscribe è sempre vuoto,
    ** quindi uso location per trovare la posizione attuale
    */
   console.log('check start')
    this.activatedRoute.url.pipe(takeUntil(this.onDestroy$)).subscribe(url => {
      console.log('activated route fired')
      this.authService.isLoggedIn().pipe(
        map(isLoggedIn => {
          console.log('isLoggedIn:', isLoggedIn)
          if(this.location.path() === '/error404') {
            this.hideLayout = true;
          } else if(isLoggedIn) {
            this.hideLayout = false;
          } else if (!isLoggedIn && this.location.path() === '/home') {
            this.hideLayout = true;
            this.router.navigate(['/error404']);
          }
        })
      ).subscribe();
    })

  }

  ngOnDestroyForChildren(): void {}
}
