<div *ngIf="profiles?.length; else noProfiles">
  <h1>{{ 'PROFILE.SELECT_PROFILE' | translate }}</h1>
  <div class="row">
    <custom-card
      *ngFor="let p of profiles"
      class="col-6"
      card-title="{{ p.office ? p.office.name : 'GESTIONE_UNITA_ORGANIZZATIVA.SECTION_TITLE' }}"
      [actions]="!profileIsActive(p.profile) ? profileCardActions : profileCardActivatedActions"
      [payload]="p.profile"
      [infoItems]="!p.office?.enabled ? [infoItemCard] : []"
      [card-selected-action]="profileIsActive(p.profile)">

      <label *ngIf="p.profile.roles.length">{{'PROFILE.ROLES' | translate}}</label>
      <ul *ngIf="p.profile.roles.length; else noRoles">
        <li *ngFor="let role of p.profile.rolesNames">{{role}}</li>
      </ul>
      <ng-template #noRoles clas="card_item">
        <label>{{'PROFILE.NO_ROLE' | translate}}</label>
      </ng-template>
    </custom-card>
  </div>
</div>

<ng-template #noProfiles>
  <p-messages *ngIf="profiles === null" [(value)]="noProfileMessage" [enableService]="false" [closable]="false"></p-messages>
</ng-template>
