import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {
  constructor(private readonly router: Router) {}

  goTo(path: string, extras?: NavigationExtras) {
    this.router.navigate([path], extras);
  }

  goBack(extras?: NavigationExtras) {
    this.router.navigate(['../'], extras);
  }

}
