import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { Error404Component } from './shared/components/error404/error404.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    component: HomeComponent,
    data: { title: 'Home', breadcrumb: 'Home' },
  },
  {
    path: ':idp/home',
    component: HomeComponent,
    data: { title: 'Home', breadcrumb: 'Home' },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: { title: 'Profilo', breadcrumb: '' },
  },
  {
    path: 'gestione-modulo',
    loadChildren: () =>
      import('./modules/gestione-modulo/gestione-modulo.module').then(
        (m) => m.GestioneModuloModule
      ),
  },
  {
    path: 'unita-organizzativa',
    loadChildren: () =>
      import('./modules/gestione-unita-organizzativa/gestione-unita-organizzativa.module').then(
        (m) => m.GestioneUnitaOrganizzativaModule,
      ),
  },
  {
    path: 'gestione-applicazione',
    loadChildren: () =>
      import('./modules/gestione-applicazione/gestione-applicazione.module').then(
        (m) => m.GestioneApplicazioneModule),
  },
  {
    path: 'utente',
    loadChildren: () =>
      import('./modules/gestione-utente/gestione-utente.module').then(
        (m) => m.GestioneUtenteModule,
      ),
  },
  {
    path: 'tenant',
    loadChildren: () =>
      import('./modules/gestione-tenant/gestione-tenant.module').then(
        (m) => m.GestioneTenantModule,
      ),
  },
  {
    path: 'error404',
    component: Error404Component
  },
  /* {
    path: 'caricamento-massivo',
    loadChildren: () =>
      import('./modules/caricamento-massivo/caricamento-massivo.module').then(
        (m) => m.CaricamentoMassivoModule,
      ),
  }, */
  /* {
    path: 'gestione-ruolo',
    loadChildren: () =>
      import('./modules/gestione-ruolo/gestione-ruolo.module').then(
        (m) => m.GestioneRuoloModule,
      ),
  }, */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
