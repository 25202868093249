/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Injector, inject } from '@angular/core';
import { FlowersBasePageComponent } from '@pandora/flowers';
import { UserService } from '../core/services/user.service';
import { UiElement } from '../core/services/ui-element.service';
import { NavigateService } from '../core/services/navigate.service';

@Component({
  selector: 'flowers-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends FlowersBasePageComponent {

  userService: UserService;
  navigateService: NavigateService;
  uiElement: UiElement;
  isReady = false;

  constructor(injector: Injector) {
    super(injector);

    this.userService = inject(UserService);
    this.navigateService = inject(NavigateService);
    this.uiElement = inject(UiElement);
  }

  async init() {
    const interval = setInterval(() => {
      if (this.userService.user) {
        if (this.userService.user['authorization-details'].authorization.length && (!('profileSelected' in this.userService.user) || !this.userService.user.profileSelected) && this.userService.applicationNameModel()) {
          this.navigateService.goTo('profile');
        } else {
          this.isReady = true;
        }
        clearInterval(interval);
      }
      
    }, 100);

  }

  ngOnDestroyForChildren(): void {}

  ngOnInitForChildren(): void {
    this.init();
  }

  pageName(): string {
    return 'HomeComponent';
  }
}
