import {Injectable} from '@angular/core';
import { DataStorageEnum } from '../models/data-storage-enum.model';

@Injectable({
  providedIn: 'root'
})
export class DataStorage {
  private dataStorage = {};

  updateData(key: DataStorageEnum, data: any) {
    this.dataStorage[key] = data;
  }

  getData(key: DataStorageEnum) {
    return this.dataStorage[key];
  }

  clearData(key: DataStorageEnum) {
    this.dataStorage[key] = undefined;
  }

  clearAllData() {
    this.dataStorage = {};
  }
}
