/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Injector, Inject } from '@angular/core';
import {
  ActionItem,
  FlowersBasePageComponent,
  CustomAlertTypeEnum,
  InfoItem,
  ProfileModel,
  User,
  AuthorizationModel
} from '@pandora/flowers';
import { TranslateService } from '@ngx-translate/core';
import { UiElement } from '../core/services/ui-element.service';
import { Message } from 'primeng/api';
import { NavigateService } from '../core/services/navigate.service';
import { UserService } from '../core/services/user.service';
import { environment } from '../../environments/environment';
import { UserInfo } from '../shared/models/userinfo.model';
import { GestioneUserInfoService } from '../shared/services/gestione-user-info.service';
import { lastValueFrom } from 'rxjs';
import { GestioneUnitaOrganizzativaModel } from '../shared/models/gestione-unita-organizzativa.model';

@Component({
  selector: 'auth-out-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends FlowersBasePageComponent {
  CustomAlertTypeEnum = CustomAlertTypeEnum;
  profileCardActions: ActionItem[] = [
    new ActionItem(
      'PROFILE.SELECT_PROFILE',
      (profile: ProfileModel) => {
        this.activateProfile(profile);
      },
      'pi-check'
    ),
  ];
  noProfileMessage: Message[];
  profileCardActivatedActions: ActionItem[] = [];
  infoItemCard: InfoItem =
    new InfoItem(
      'pi-ban',
      'GESTIONE_UNITA_ORGANIZZATIVA.FORM.UNITA_ORGANIZZATIVA_DISABLE',
      null,
      'primary'
    );
    profiles: {
      profile: ProfileModel,
      office: GestioneUnitaOrganizzativaModel
    }[] = [];

  constructor(
    injector: Injector,
    private readonly uiElement: UiElement,
    private readonly translateService: TranslateService,
    private readonly navigateService: NavigateService,
    public userService: UserService,
    @Inject(GestioneUserInfoService) private readonly gestioneUserInfoService: GestioneUserInfoService) {
    super(injector);
    this.profileCardActivatedActions = [
      new ActionItem(this.translateService.instant('PROFILE.PROFILE_SELECTED'), null),
    ];

    this.noProfileMessage = [{ severity: 'info', summary: '', detail: this.translateService.instant('PROFILE.NO_PROFILE') }];
  }

  profileIsActive(profile: ProfileModel) {
    if (!this.userService.user.profileSelected) {
      return false;
    }
    return profile.uuid === this.userService.user.profileSelected.uuid;
  }

  activateProfile(profile: ProfileModel) {
    const user: User = {
      ...this.userService.user,
      profileSelected: profile,
    }

    this.userService.buildUser(user);
    this.navigateService.goTo('home');
  }

  async getProfiles() {
    const sourceUserInfo$ = this.gestioneUserInfoService.getWithWrapper();
    const responseUserInfo: UserInfo = await lastValueFrom(sourceUserInfo$);

    for (let idx = 0; idx < this.userService.user['authorization-details'].authorization.length; idx++) {
      const element: AuthorizationModel = this.userService.user['authorization-details'].authorization[idx];

      if (element.application === environment.applicationName) {

        const responseProfiles = element.profiles.slice().sort(function(a, b) {
          var textA = a.office.toUpperCase();
          var textB = b.office.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        responseProfiles.forEach((profile: ProfileModel) => {
          const currentProfile = responseUserInfo.profiles.find(p => p.uuid === profile.uuid);

          this.profiles.push(
            {
              profile: {
                ...profile,
                roles: currentProfile.roles.map(r => r.code),
                rolesNames: currentProfile.roles.map(r => r.name)
              },
              office: {
                code: currentProfile.uoCode,
                description: null,
                name: currentProfile.uoName
              }

            }
          );

          if (this.userService.user['authorization-details'].authorization.length - 1 === idx && this.profiles.length < 1) {
            this.profiles = null;
          }
        });

      }
    }

    if (!this.userService.user['authorization-details'].authorization.length) {
      this.profiles = null;
      this.navigateService.goTo('home');
    }
  }

  ngOnDestroyForChildren(): void {}

  ngOnInitForChildren(): void {
    this.getProfiles();
  }

  pageName(): string {
    return 'ProfileComponent';
  }
}
