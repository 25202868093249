import { KeycloakService } from 'keycloak-angular';
import { environment } from './environments/environment';
import { UserService } from './app/core/services/user.service';

/* export function initializeKeycloak(
  keycloak: KeycloakService
): () => Promise<boolean> {
  return () =>
    keycloak.init({
      config: environment.keycloak,
      initOptions: {
        checkLoginIframe: true,
        checkLoginIframeInterval: 25,
      },
      loadUserProfileAtStartUp: true,
    });
} */


export function initializeKeycloak(keycloak: KeycloakService, userService: UserService): () => Promise<void> {
  return () => {
    return new Promise(async (resolve, reject) => {
      try {

        await keycloak.init({
          config: environment.keycloak,
          initOptions: {
            checkLoginIframe: true,
            checkLoginIframeInterval: 25,
          },
          enableBearerInterceptor: true,
          bearerExcludedUrls: ['/assets'],
          loadUserProfileAtStartUp: true,
        })

        /* const keycloakAuth = keycloak.getKeycloakInstance();

        keycloakAuth.onTokenExpired = () => {
          if (keycloakAuth.isTokenExpired()) {
            userService.logout();
          }
        } */

        resolve();
      } catch (error) {
        reject(error);
      }
    })
  }
};
