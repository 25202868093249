import { Injectable, Injector, inject } from '@angular/core';
import {
  CustomBaseHttpLiteService,
  CustomSearchCriteria,
  ToastService,
} from '@pandora/flowers';
import { ConfigurationEndpointsConstants } from '../constans/configuration-endpoints.constants';
import { AuthOutEndpoints } from '../endpoints/auth-out.endpoints';
import { AvatarModel, UserInfo } from '../models/userinfo.model';
import { Observable, of, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { TenantInfoModel } from '../models/tenant-info.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class GestioneUserInfoService extends CustomBaseHttpLiteService<UserInfo> {

  isMaster: boolean;
  userInfo: UserInfo;
  tenantInfo: TenantInfoModel;
  avatar: AvatarModel;
  toastService: ToastService;
  translateService: TranslateService;

  constructor(injector: Injector,) {
    super(injector);
    this.toastService = inject(ToastService);
    this.translateService = inject(TranslateService);
  }

  override getHost(): string {
    return ConfigurationEndpointsConstants.API.url;
  }

  buildSearchParams(criteria: CustomSearchCriteria): any {
    const params = {};
    return params;
  }

  clear() {
    this.isMaster = undefined;
    this.userInfo = undefined;
    this.tenantInfo = undefined;
  }

  getBaseResourceEndpoint(): string {
    return AuthOutEndpoints.GESTIONE_USER_INFO;
  }

  getServiceName(): string {
    return 'GestioneUserInfoService';
  }

  public getWithWrapper(): Observable<UserInfo> {
    /* if (!this.userInfo) {
      const url = `${this.buildUrl()}${'/authoutwrapper'}`;
      return this.httpClient.get(url).pipe(map((response) => this.userInfo = response['content'] as UserInfo));
    } else {
      return of(this.userInfo);
    } */
    const url = `${this.buildUrl()}${'/authoutwrapper'}`;
    return this.httpClient.get(url).pipe(map((response) => this.userInfo = response['content'] as UserInfo));
  }

  public getIsMaster(): Observable<boolean> {
    if (this.isMaster === undefined) {
      const url = `${this.buildUrl()}${'/tenant/isMaster'}`;
      return this.httpClient.get(url).pipe(map((response) => this.isMaster = response as boolean));
    } else {
      return of(this.isMaster);
    }
  }

  public getTenantInfo(urlParam): Observable<TenantInfoModel> {
    if (!this.tenantInfo) {
      const url = `${this.buildUrl(null, false)}${'tenants/'+ urlParam}`;
      return this.httpClient.get(url).pipe(map((response) => this.tenantInfo = response['content'] as TenantInfoModel));
    } else {
      return of(this.tenantInfo);
    }
  }

  public getAvatarBE(): Observable<AvatarModel> {
    if (!this.avatar) {
      const url = `${this.buildUrl(null)}/avatar`;
      return this.httpClient.get(url).pipe(map((response) => this.avatar = response['content']));
    } else {
      return of(this.avatar);
    }
  }

  public getAvatarImage(): string {
    return this.avatar?.image;
  }

  public setAvatar = (file: File): void => {
    const url = `${this.buildUrl(null)}/avatar`;

    const formData: FormData = new FormData();
    formData.append('file', file);

    this.saveFile(formData, url).subscribe((_) => {
      this.toastService.showSuccess(
        this.translateService.instant('GESTIONE_UTENTE.AVATAR_ADDED'),
        ``
      );
    });
  }

  public deleteAvatar = async (): Promise<void> => {
    const url = `${this.buildUrl(null)}/avatar`;
    const c = this.httpClient.delete(url).pipe(map((_) => {
    this.avatar = undefined;

      this.toastService.showSuccess(
        this.translateService.instant('GESTIONE_UTENTE.AVATAR_DELETED'),
        ``
      );
    }));

    await lastValueFrom(c);
  }

  public getAvatar = async (): Promise<string> => {
    const url = `${this.buildUrl(null)}/avatar`;
    const c = this.httpClient.get(url).pipe(map((response) => (response['content'] as AvatarModel).image));

    return await lastValueFrom(c);
  }

}
