import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Utils {
  previousUrl: string;
  currentUrl: string;

  constructor(router: Router) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      })
  }

  getPreviousUrl() {
    return this.previousUrl;
  }
  getCurrentUrl() {
    return this.currentUrl;
  }

  static removeExtraSpaces(sentence: string) {
    return sentence.replace(/\s+/g, ' ').trim();
  }

  static capitalizeAllWords(sentence: string) {
    const splitChars = [" ", "'"]; // caratteri su cui effettuare lo split
    let arr;
    sentence = sentence.toLowerCase(); // messa subito in lowercase, altrimenti a ogni ciclo avrei cancellato il capitalize del ciclo precedente

    for (let splitChar of splitChars) { // itero sui caratteri su cui fare lo split
      arr = Utils.removeExtraSpaces(sentence).split(splitChar);
      for (let i = 0; i < arr.length; i++) { // itero sulle parole splittate
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
     sentence = arr.join(splitChar);
    }

    return sentence;
  }

}
