import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { initializeKeycloak } from '../keycloak-initializer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthoutHttpInterceptor } from './core/services/authout-http-interceptor.service';
import { TRANSLATE_LOADER, TRANSLATE_PROVIDER } from '../translate-initializer';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FlowersModule } from '@pandora/flowers';
import { TranslateModule } from '@ngx-translate/core';
import { Utils } from './shared/utils/utils';
import { UserService } from './core/services/user.service';

@NgModule({
  declarations: [AppComponent, HomeComponent, ProfileComponent],
  imports: [
    AppRoutingModule,
    KeycloakAngularModule,
    ToastrModule.forRoot({
      newestOnTop: false,
      maxOpened: 4,
      autoDismiss: true,
    }),
    StoreModule.forRoot({}),
    FlowersModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot(TRANSLATE_LOADER)
  ],
  providers: [
    TRANSLATE_PROVIDER,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, UserService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthoutHttpInterceptor,
      multi: true,
    },
    Utils,
    UserService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
