<ng-container *ngIf="!hideLayout">
  <flowers-base-layout
    [menuItems]="uiElement.getMenuItems()"
    [applicationTitle]="'APPLICATION.TITLE'"
    [applicationSubtitle]="'APPLICATION.SUBTITLE' | translate"
    [showFooter]="false"
    [containerFluid]="true"
    [menuOpenDefault]="true"
    [showMenuItemsIcon]="false"
    [buildUser]="userService.buildUser"
    [userActions]="userService.getUserActions()"
    [additionalUserInfo]="userService.getOfficeName()"
    [avatar]="gestioneUserInfoService.getAvatarImage()"
    [changeAvatar]="gestioneUserInfoService.setAvatar"
    [removeAvatar]="gestioneUserInfoService.deleteAvatar"
    [getAvatar]="gestioneUserInfoService.getAvatar"
    [logo]="'assets/logo.png'"
  >
    <router-outlet></router-outlet>
  </flowers-base-layout>
</ng-container>

<ng-container *ngIf="hideLayout">
  <router-outlet></router-outlet>
</ng-container>

