export enum PermissionEnum {
  ENBL_PER = 'ENBL_PER:AUTH-OUT',
  SRCH_MOD = 'SRCH_MOD:AUTH-OUT',
  ADD_ROLE_P = 'ADD_ROLE_P:AUTH-OUT',
  ADD_MOD_APP = 'ADD_MOD_APP:AUTH-OUT',
  GET_UO = 'GET_UO:AUTH-OUT',
  UPD_APP = 'UPD_APP:AUTH-OUT',
  INS_ROLE = 'INS_ROLE:AUTH-OUT',
  UPD_UO = 'UPD_UO:AUTH-OUT',
  GET_APP = 'GET_APP:AUTH-OUT',
  GET_MOD = 'GET_MOD:AUTH-OUT',
  GET_USER = 'GET_USER:AUTH-OUT',
  INS_PRFL = 'INS_PRFL:AUTH-OUT',
  INS_UO = 'INS_UO:AUTH-OUT',
  DSBL_USER = 'DSBL_USER:AUTH-OUT',
  UPD_MOD = 'UPD_MOD:AUTH-OUT',
  UPD_ROLE = 'UPD_ROLE:AUTH-OUT',
  ENBL_UO = 'ENBL_UO:AUTH-OUT',
  INS_PER = 'INS_PER:AUTH-OUT',
  GET_PER = 'GET_PER:AUTH-OUT',
  DEL_USER = 'DEL_USER:AUTH-OUT',
  UPD_TENANT = 'UPD_TENANT:AUTH-OUT',
  ENBL_MOD = 'ENBL_MOD:AUTH-OUT',
  REM_ROLE_P = 'REM_ROLE_P:AUTH-OUT',
  UPD_PER = 'UPD_PER:AUTH-OUT',
  ENBL_APP = 'ENBL_APP:AUTH-OUT',
  DEL_ROLE = 'DEL_ROLE:AUTH-OUT',
  SRCH_PER = 'SRCH_PER:AUTH-OUT',
  MENU_UO = 'MENU_UO:AUTH-OUT',
  ENBL_USER = 'ENBL_USER:AUTH-OUT',
  ACT_TENANT = 'ACT_TENANT:AUTH-OUT',
  MENU_PERMISSION = 'MENU_PERMISSION:AUTH-OUT',
  MENU_ROLE = 'MENU_ROLE:AUTH-OUT',
  SRCH_UO = 'SRCH_UO:AUTH-OUT',
  DSBL_PER = 'DSBL_PER:AUTH-OUT',
  INS_APP = 'INS_APP:AUTH-OUT',
  SRCH_ROLE = 'SRCH_ROLE:AUTH-OUT',
  SRCH_USER = 'SRCH_USER:AUTH-OUT',
  INS_MOD = 'INS_MOD:AUTH-OUT',
  MENU_USER = 'MENU_USER:AUTH-OUT',
  DSBL_APP = 'DSBL_APP:AUTH-OUT',
  GET_ROLE = 'GET_ROLE:AUTH-OUT',
  DEL_PRFL = 'DEL_PRFL:AUTH-OUT',
  MENU_MODULE = 'MENU_MODULE:AUTH-OUT',
  MENU_APPLICATION = 'MENU_APPLICATION:AUTH-OUT',
  UPD_USER = 'UPD_USER:AUTH-OUT',
  SRCH_APP = 'SRCH_APP:AUTH-OUT',
  DSBL_UO = 'DSBL_UO:AUTH-OUT',
  DSBL_MOD = 'DSBL_MOD:AUTH-OUT',
  INS_USER = 'INS_USER:AUTH-OUT',
  REM_MOD_APP = 'REM_MOD_APP:AUTH-OUT',
  VIEW_ROLE_FROM_PERM = 'VIEW_ROLE_FROM_PERM:AUTH-OUT',
  REM_ROLE_FROM_PERM = 'REM_ROLE_FROM_PERM:AUTH-OUT',
  RESET_PWD = 'RESET_PWD:AUTH-OUT_IDP_INTERNO',
  MENU_TENANT = 'MENU_TENANT:AUTH-OUT',
  SRCH_TENANT = 'SRCH_TENANT:AUTH-OUT'
}
