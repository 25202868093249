import { Injectable } from '@angular/core';
import { AuthFacade, AuthService, AuthorizationModel, User } from '@pandora/flowers';
import { DataStorage } from './storage.service';
import { DataStorageEnum } from '../models/data-storage-enum.model';
import { NavigateService } from './navigate.service';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { UiElement } from './ui-element.service';
import { KeycloakService } from 'keycloak-angular';
import { AvatarModel, UserInfo } from '../../shared/models/userinfo.model';
import { UserInfoProfile } from '../../shared/models/userinfoprofile.model';
import { lastValueFrom, noop, tap } from 'rxjs';
import { GestioneUserInfoService } from '../../shared/services/gestione-user-info.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  user: User;
  userActions: MenuItem[] = [];
  officeName = '';
  tenant = '';
  lastRefreshTokenExp: number;

  constructor(
    private readonly authFacade: AuthFacade,
    private readonly authService: AuthService,
    private readonly dataStorage: DataStorage,
    private readonly navigateService: NavigateService,
    private readonly translateService: TranslateService,
    private readonly uiElement: UiElement,
    private readonly keycloak: KeycloakService,
    readonly gestioneUserInfoService: GestioneUserInfoService
    ) {
    /* setTimeout(() => {
      this.uiElement.clearMenuItems();
    }, 115); */

    const interval = setInterval(() => {
      if (this.uiElement.checkLanguageAvailable()) {
        this.uiElement.clearMenuItems();

        clearInterval(interval);
      }
    }, 10);

    this.initUserService();
  }

  initUserService() {
    this.authFacade.getUser().subscribe(async (user: User) => {
      this.setUser(user);

      const interval2 = setInterval(() => {
        if (this.keycloak.getKeycloakInstance() && 'refreshTokenParsed' in this.keycloak.getKeycloakInstance()) {
          if (this.lastRefreshTokenExp === undefined) {
            this.lastRefreshTokenExp = this.keycloak.getKeycloakInstance().refreshTokenParsed.exp;
          }
  
          if (this.lastRefreshTokenExp !== this.keycloak.getKeycloakInstance().refreshTokenParsed.exp) {
            this.lastRefreshTokenExp = this.keycloak.getKeycloakInstance().refreshTokenParsed.exp;
  
            this.authService
              .checkAuthAndGetUserData()
              .pipe(
                tap((user: User) => {
                  let userInfo: User = {
                    ...user,
                  }
  
                  if ('profileSelected' in this.user && this.user.profileSelected) {
                    user['authorization-details'].authorization.forEach((element: AuthorizationModel) => {
                      if (element.application === environment.applicationName) {
                        userInfo = {
                          ...userInfo,
                          profileSelected: element.profiles.find((p) => p.uuid === this.user.profileSelected.uuid)
                        }
                      }
                    });
                  } else {
                    user['authorization-details'].authorization.forEach((element: AuthorizationModel) => {
                      if (element.application === environment.applicationName && element.profiles.length === 1) {
                        userInfo = {
                          ...userInfo,
                          profileSelected: element.profiles[0]
                        }
                      }
                    });
                  }
  
                  this.authService.storeUser(userInfo);
                  this.user = userInfo;
  
                  this.setUser(this.user);
                  
                  clearInterval(interval2);
                }),
              )
              .subscribe(noop);
          }
        }
        
      }, 1000);
    });
  }

  setUser(user: User) {

    if(user && !('profileSelected' in user)) {
      this.uiElement.clearMenuItemsNoProfileSelected();
    }

    if (this.keycloak.getKeycloakInstance()) {
      // set user
      this.user = user;

      this.keycloak.getKeycloakInstance().onAuthRefreshSuccess()
    }

    // logout if refresh_token expired
    if (this.user) {
      if (this.keycloak.getKeycloakInstance() && 'refreshTokenParsed' in this.keycloak.getKeycloakInstance()) {
        const interval = setInterval(() => {
          if (Math.floor(Date.now() / 1000) > this.keycloak.getKeycloakInstance().refreshTokenParsed.exp || !this.keycloak.getKeycloakInstance().refreshTokenParsed) {
            this.logout();
  
            clearInterval(interval);
          }
        }, 1000);
      }

      // set entryComponentPath and tenantPath
      this.dataStorage.updateData(DataStorageEnum.USER, this.user);

      let tenant = '';
      if (this.tenant !== '') {
        tenant = this.tenant;
      } else {
        tenant = this.user['authorization-details']['tenant'];
      }

      this.setTenantPath(tenant);
      this.authService.setEntryComponentPath(this.tenant.concat('/home'));

      // set flowers-base-layout variables
      this.userActions = this.userActions.filter((obj: MenuItem) => obj.label !== this.translateService.instant('PROFILE.CHANGE_PROFILE') && obj.label !== this.translateService.instant('PROFILE.CHANGE_PASSWORD'));

      const authorization = this.applicationNameModel();
      if (authorization) {
        if (authorization.profiles.length > 1) {
          this.userActions.push({
            label: this.translateService.instant('PROFILE.CHANGE_PROFILE'),
            command: () => {
              this.navigateService.goTo('profile');
            },
            id: '10'
          });
          
          this.userActions.sort((a, b) => Number(a.id) - Number(b.id));
        }

        if (('profileSelected' in this.user && this.user.profileSelected && authorization.profiles.length > 1) || authorization.profiles.length === 1) {
          this.uiElement.initMenuItems();
        }

      } else {
        // utente senza environment.applicationName (AUTH-OUT)
        this.uiElement.initMenuItems(true);
      }

      // utente senza autorizzazioni
      if (!this.user['authorization-details'].authorization.length) {
        this.uiElement.initMenuItems(true);
      }

      this.gestioneUserInfoService.getTenantInfo(tenant).subscribe(tenantInfo => {
        
        if(!tenantInfo?.external) {
          this.userActions.push({
            label: this.translateService.instant('PROFILE.CHANGE_PASSWORD'),
            command: () => this.changePassword(),
            id: '80'
          })
          
          this.userActions.sort((a, b) => Number(a.id) - Number(b.id));
        }
      })
    } else {
      if (this.authService.entryComponentPath === undefined) {
        this.authService.setEntryComponentPath('home');
      }

      if (window.location.href.indexOf('home') === -1) {
        this.navigateService.goTo('home');
      }
    }
  }

  applicationNameModel() {
    let response: AuthorizationModel;
    
    if (this.user) {
      this.user['authorization-details'].authorization.forEach((element: AuthorizationModel) => {
        if (element.application === environment.applicationName) {
          response = element;
        }
      });
    }

    return response;
  }

  logout() {
    this.uiElement.clearMenuItems();
    this.dataStorage.clearAllData();
    this.gestioneUserInfoService.clear();

    const postLogoutUrl = window.location.origin.concat('/').concat(this.authService.entryComponentPath);
    this.authService.logout(postLogoutUrl);
  }

  changePassword() {
    // this.uiElement.clearMenuItems();
    // this.dataStorage.clearAllData();
    // this.gestioneUserInfoService.clear();

    const postChangeUrl = window.location.origin.concat('/').concat(this.authService.entryComponentPath)

    this.authService.changePassword(postChangeUrl);
  }

  getUser(): User {
    return this.user;
  }

  setTenantPath(tenant: string) {
    this.tenant = tenant;
  }

  getUserActions(): MenuItem[] {
    return this.userActions;
  }

  getOfficeName(): string {
    return this.officeName;
  }

  async updateOtherInfo() {
    if (this.user) {
      const sourceUserInfo$ = this.gestioneUserInfoService.getWithWrapper();
      const responseUserInfo: UserInfo = await lastValueFrom(sourceUserInfo$);

      if (this.user.profileSelected) {
        this.officeName = responseUserInfo.profiles.find((p: UserInfoProfile) => p.uuid === this.user.profileSelected.uuid).uoName;
      }

      if (responseUserInfo.avatarURL) {
        this.updateAvatar();
      }

    }
  }

  async updateAvatar() {
    if (this.user) {
      const source$ = this.gestioneUserInfoService.getAvatarBE();
      const response: AvatarModel = await lastValueFrom(source$);

      this.gestioneUserInfoService.avatar = response;
    }
  }

  buildUser = (user: User): User => {
    /* if (!this.user) {
      this.user = user;
    }

    if (!this.user) {
      return null;
    } */

    let userInfo: User = {
      ...user,
    }

    user['authorization-details'].authorization.forEach((element: AuthorizationModel) => {
      if (element.application === environment.applicationName && element.profiles.length === 1) {
        userInfo = {
          ...userInfo,
          profileSelected: element.profiles[0]
        }
      }
    });

    this.authService.clearUser();
    this.authService.storeUser(userInfo);
    this.dataStorage.updateData(DataStorageEnum.USER, userInfo);

    this.user = userInfo;

    this.updateOtherInfo();

    return userInfo;
  }

}
